///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Element.prototype.escamotable = function (options = {}){
    const $this = this;
    const handleClass = options.handle ? options.handle : "escamotable-handle";
    const bodyClass = options.body ? options.body : "escamotable-body";
    this.handle = this.find(':scope > .' + handleClass)[0];
    this.section = this.find(':scope > .' + bodyClass)[0];
    const height = this.section.offsetHeight;
    this.section.setStyle('height', 0);
    //
    const fix = function(section){
        if(section.hasClass('open-fix')){
            section.removeClass('open-fix');
            section.setStyle('height', height + 'px');
        }
    }
    //
    this.section.addEventListener('transitionend', function(e){
        if(parseInt(this.style.height) > 0) this.addClass('open-fix');
    });
    //
    this.handle.addEventListener('click', function(e){
            e.preventDefault();
            let close = !this.hasClass('open');
            fix($this.section);
            $this.section.setStyle('height', 0);
            $this.handle.removeClass('open');
            if(close){
                this.addClass('open');
                $this.section.setStyle('height', height + 'px');
            }
        });

}

